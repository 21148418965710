import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

let isDev = false;
try {
  isDev = window?.location?.host?.includes("localhost") ?? false;
} catch (error) {
  isDev = false;
}
const urlFunctions = isDev ? "http://localhost:5001/catalogo-mais-odonto/us-central1" : process.env.REACT_APP_URL_FUNCTIONS;
const db = getFirestore(app, isDev ? "banco-dev" : "(default)");
const auth = getAuth(app);
const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);

export { db, auth, storage, urlFunctions };
